import fetch from "./xhr/index";

/**
 * 工单-任务工单列表
 * @param
 */
export const taskWorkList= (param) => fetch("GET", "/orderTask/task/list", param);

/**
 * 工单-工单类型模板-1任务 2审批
 * @param
 */
export const workOrderType= (param) => fetch("GET", "/cms/workOrder/selectWorkOrderList", param);

/**
 * 工单-发起任务
 * @param
 */
export const launchTask = (param) => fetch("POST", "/orderTask/task/createTask", param);

/**
 * 工单-发起任务，编辑
 * @param
 */
 export const updateWork= (param) => fetch("POST", "/orderTask/task/updateTask", param);

/**
 * 工单-获取角色
 * @param
 */
export const getTemplateRole = (param) => fetch("GET", "/orderTask/task/getRoleByIds", param);

/**
 * 工单-获取详情
 * @param
 */
export const taskDetail = (param) => fetch("GET", "/orderTask/task/getDetail", param);

/**
 * 工单-发起任务，获取模板
 * @param
 */
export const workOrderTemplate= (param) => fetch("GET", "/orderTask/task/getSetting", param);

/**
 * 工单-详情，发布反馈
 * @param
 */
export const addWorkLog= (param) => fetch("POST", "/orderTask/task/feedback", param);

/**
 * 工单-详情，完成任务
 * @param
 */
export const completeTask = (param) => fetch("GET", "/orderTask/task/nextStep", param);

/**
 * 工单-详情，工单驳回
 * @param
 */
 export const rejectTask = (param) => fetch("GET", "/orderTask/task/rollbackStep", param);

 /**
  * 工单-详情，工单驳回步骤下拉
  * @param
  */
 export const rejectTaskSelect = (param) => fetch("GET", "/orderTask/task/getAllFlow", param);
 

/**
 * 工单-详情，终止工单
 * @param
 */
export const closeTask = (param) => fetch("GET", "/orderTask/task/termination", param);

/**
 * 新建工单类型
 * @param
 */
export const insertWorkOrder = (param) => fetch("POST", "/cms/workOrder/insertWorkOrder", param);

/**
 * 删除 修改是否应用公司
 * @param
 */
export const updateWorkOrderById = (param) => fetch("GET", "/cms/workOrder/updateWorkOrderById", param);

/**
 * 编辑工单类型
 * @param
 */
export const updateWorkOrder = (param) => fetch("POST", "/cms/workOrder/updateWorkOrder", param);

/**
 * 详情查询
 * @param
 */
export const selectWorkOrder = (param) => fetch("GET", "/cms/workOrder/selectWorkOrder", param);

/**
 * 工单设置 修改流程步骤改为发起人自选
 * @param
 */
export const updateApproveType = (param) => fetch("GET", "/cms/workOrder/updateApproveType", param);